import Vue from 'vue';
import axios from 'axios';
// Vue.use(axios);  axios不支持use安装
// import store from '../store/index'
// import { Message } from 'element-ui'
import Qs from 'qs'
// import { VIEW_LOADING } from '../store/types'
//拦截器
// 请求时
axios.interceptors.request.use(function (config) {
  // 请求之前，做点事
  // config.headers={"Content-Type":"xxxxx"}  添加请求头
  // console.log('显示loading')
  // store.dispatch(VIEW_LOADING,true)
  // store.commit('showLoading')
  if(config.data){
    config.data = Qs.stringify(config.data)
  }
  return config;
}, function (error) {
  // 错误请求，做点事
  // Message({
  //   message: error.msg,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  // store.commit('hideLoading')
  return Promise.reject(error);
});

// 响应式，数据返回时
axios.interceptors.response.use(function (response) {
  // console.log('loading消失')
  // store.dispatch(VIEW_LOADING,false)
  // store.commit('hideLoading')
  return response;
}, function (error) {
  // Message({
  //   message: error.msg,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  // store.commit('hideLoading')
  return Promise.reject(error);
});

Vue.prototype.$axios = axios;// 组件里面: this.$axios;
window.axios = axios; //组件，非组件里面都可以  axios()

export default axios;