'use strict'

module.exports = {
    project_name: 'empty',
    package_name: 'empty',
    port: 8000,
    // url: '/local',
    url: 'https://uz.hanlandglobal.com:4433'
    // url: 'http://api.yoursliving.co.uk'
    // url: process.env.VUE_APP_BASEURL
}
