<template>
  <div class="index">
    <div class="article" v-html='article.content'></div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'index',
  data(){
    return {
      article:{}
    }
  },
  mounted(){
    this.getArticle();
    this.sendPV();
  },
  methods:{
    getArticle(){
      let that = this
      this.axios.get(this.url+'/api/media/article',{params:{'id':this.$route.query.id}}).then(function(res){
        that.article = res.data.data;
      }).catch(function() {
      });
    },
    sendPV(){
      const {aplus_queue} = window;
      aplus_queue.push({
      action: 'aplus.sendPV',
        arguments: [{
            is_auto: false
        }, {
            param1:111,
            param2:'222'
        }]
      });
    }
  }
}
</script>
<style lang="scss" scoped>
.index{
  width: 750px;
  font-size: 16px;
  margin: 0 auto;
  .article{
    width: 100%;
    margin: 0 auto;
  }
  // .article /deep/ img{
  //   width: 100%;
  //   max-width: 700px !important;
  //   height: auto !important;
  //   margin: 0 auto;
  // }
}
@media only screen and (max-width: 1024px){
  .index{
    width: 100%;
    font-size: 12px;
    .article{
      width: 100%;
      margin: 0 auto;
    }
    .article /deep/ img{
      width: 100%;
      max-width: 80% !important;
      height: auto !important;
      margin: 0 auto;
    }
  }


}
</style>
